var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"mb-6"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: `primary--text` }),expression:"{ class: `primary--text` }"}],staticClass:"panel-title-container white--text pa-6",style:({
      'border-bottom-left-radius': _vm.expand ? '0' : '5px',
      'border-bottom-right-radius': _vm.expand ? '0' : '5px',
    }),on:{"click":function($event){_vm.expand = !_vm.expand}}},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-6"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('basic-setting.finish-basic-setting')))]),_c('div',{staticClass:"icon-container"},[_c('v-icon',{class:{
            'white--text': true,
            'fi fi-rr-angle-square-down': !_vm.expand,
            'fi fi-rr-angle-square-up': _vm.expand,
          }})],1)]),_c('div',{staticClass:"finish-count-text"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.finish-basic-setting-count', { finish: _vm.progressValue, total: _vm.totalCount, }))+" ")]),_c('div',{staticClass:"progress-container"},[_c('v-progress-linear',{staticClass:"basic-setting-progress",attrs:{"height":"6","rounded":"","background-color":"#144162","color":"accent"},model:{value:(_vm.progressPercentage),callback:function ($$v) {_vm.progressPercentage=$$v},expression:"progressPercentage"}})],1)]),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],staticClass:"ma-0"},[_c('v-col',{staticClass:"px-0 pt-3 pb-7"},[_c('div',{staticClass:"setting-item-wrapper"},[_c('div',{staticClass:"setting-item-container"},[_c('div',{class:{
                'setting-item': true,
                check: _vm.basicSettingProgress.language,
              }},[(_vm.basicSettingProgress.language)?_c('v-icon',{staticClass:"check-icon fi fi-br-check"}):_vm._e(),_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.store-lang'))+" ")]),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.support-lang'))+" "),_c('span',{staticClass:"default-text"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.default-lang'))+" ")]),_c('span',{staticClass:"default-option"},[_vm._v(_vm._s(_vm.$t('common.zh'))+" ")])])]),_c('div',{staticClass:"right-container"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","text":_vm.basicSettingProgress.language},on:{"click":function($event){return _vm.redirectPage(_vm.basicSettingProgress.language ? '' : 'GeneralSetting')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.basicSettingProgress.language ? 'status.completed' : 'basic-setting.set-now'))+" ")])],1)],1)]),_c('div',{staticClass:"setting-item-container"},[_c('div',{class:{
                'setting-item': true,
                check: _vm.basicSettingProgress.currency,
              }},[(_vm.basicSettingProgress.currency)?_c('v-icon',{staticClass:"check-icon fi fi-br-check"}):_vm._e(),_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.store-currency'))+" "),_c('v-icon',{staticClass:"fi fi-ss-info accent--text"})],1),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.set-store-currency'))+" "),_c('span',{staticClass:"default-text"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.default-currency'))+" ")]),_c('span',{staticClass:"default-option"},[_vm._v(_vm._s(_vm.$t('common.hkd'))+" ")])])]),_c('div',{staticClass:"right-container"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","text":_vm.basicSettingProgress.currency},on:{"click":function($event){return _vm.redirectPage(_vm.basicSettingProgress.currency ? '' : 'GeneralSetting')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.basicSettingProgress.currency ? 'status.completed' : 'basic-setting.set-now'))+" ")])],1)],1)]),_c('div',{staticClass:"setting-item-container"},[_c('div',{class:{
                'setting-item': true,
                check: _vm.basicSettingProgress.paymentSetting,
              }},[(_vm.basicSettingProgress.paymentSetting)?_c('v-icon',{staticClass:"check-icon fi fi-br-check"}):_vm._e(),_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.payment-method'))+" ")]),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.set-payment-method'))+" ")])]),_c('div',{staticClass:"right-container"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","text":_vm.basicSettingProgress.paymentSetting},on:{"click":function($event){return _vm.redirectPage(_vm.basicSettingProgress.paymentSetting ? '' : 'PaymentSetting')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.basicSettingProgress.paymentSetting ? 'status.completed' : 'basic-setting.set-now'))+" ")])],1)],1)]),_c('div',{staticClass:"setting-item-container"},[_c('div',{class:{
                'setting-item': true,
                check: _vm.basicSettingProgress.deliverySetting,
              }},[(_vm.basicSettingProgress.deliverySetting)?_c('v-icon',{staticClass:"check-icon fi fi-br-check"}):_vm._e(),_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.delivery-method'))+" ")]),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.set-delivery-method'))+" ")])]),_c('div',{staticClass:"right-container"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","text":_vm.basicSettingProgress.deliverySetting},on:{"click":function($event){return _vm.redirectPage(_vm.basicSettingProgress.deliverySetting ? '' : 'DeliverySetting')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.basicSettingProgress.deliverySetting ? 'status.completed' : 'basic-setting.set-now'))+" ")])],1)],1)]),_c('div',{staticClass:"setting-item-container"},[_c('div',{class:{
                'setting-item': true,
                check: _vm.basicSettingProgress.icon,
              }},[(_vm.basicSettingProgress.icon)?_c('v-icon',{staticClass:"check-icon fi fi-br-check"}):_vm._e(),_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.store-logo'))+" ")]),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.upload-store-logo'))+" ")])]),_c('div',{staticClass:"right-container"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","text":_vm.basicSettingProgress.icon},on:{"click":function($event){return _vm.redirectPage(_vm.basicSettingProgress.icon ? '' : 'GeneralSetting')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.basicSettingProgress.icon ? 'status.completed' : 'basic-setting.set-now'))+" ")])],1)],1)]),_c('div',{staticClass:"setting-item-container"},[_c('div',{class:{
                'setting-item': true,
                check: _vm.basicSettingProgress.product,
              }},[(_vm.basicSettingProgress.product)?_c('v-icon',{staticClass:"check-icon fi fi-br-check"}):_vm._e(),_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.upload-product'))+" ")]),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.upload-first-product'))+" ")])]),_c('div',{staticClass:"right-container"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","text":_vm.basicSettingProgress.product},on:{"click":function($event){return _vm.redirectPage(_vm.basicSettingProgress.product ? '' : 'ProductList')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.basicSettingProgress.product ? 'status.completed' : 'basic-setting.set-now'))+" ")])],1)],1)]),_c('div',{staticClass:"setting-item-container"},[_c('div',{staticClass:"blue-border"}),_c('div',{class:{
                'setting-item': true,
                check: _vm.basicSettingProgress.customDomain,
              }},[(_vm.basicSettingProgress.customDomain)?_c('v-icon',{staticClass:"check-icon fi fi-br-check"}):_vm._e(),_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.custom-domain'))+" ")]),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t('basic-setting.set-custom-domain'))+" ")])]),_c('div',{staticClass:"right-container"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","text":_vm.basicSettingProgress.customDomain},on:{"click":function($event){return _vm.redirectPage(_vm.basicSettingProgress.customDomain ? '' : 'GeneralSetting')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.basicSettingProgress.customDomain ? 'status.completed' : 'basic-setting.set-now'))+" ")])],1)],1)])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }