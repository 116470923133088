//@ts-nocheck 

import _Vue from 'vue';
import store from '@/store';
import i18n from '@/plugins/i18n';

export function CommonFunc(Vue: typeof _Vue): void {
    Vue.prototype.$common = {
        log(value: any): void {
            console.log(value)
        },
        getMediaPath(): string {
            return 'https://new.amazingjackie.com/media/';
        },
        getPlanChipColor(type: string): string {
            if (type === 'trial') {
                return 'accent'
            }

            return 'secondary'
        },
        getOrderStatusChipColor(type: string): string {
            let chipColor = 'primary'
            switch (type) {
                case 'awaiting_payment':
                    chipColor = '#C9C9C9';
                    break;
                case 'unverified_payment':
                    chipColor = 'accent';
                    break;
                case 'paid':
                    chipColor = 'success';
                    break;
                case 'preparing':
                    chipColor = 'info';
                    break;
                case 'delivering':
                    chipColor = 'primary';
                    break;
                case 'completed':
                    chipColor = 'accent';
                    break;
                case 'cancelled':
                    chipColor = 'secondary';
                    break;
                case 'refunded':
                    chipColor = 'error';
                    break;
            }

            return chipColor;
        },
        getPaymentStatusChipColor(type: string): string {
            let chipColor = 'info'
            switch (type) {
                case 'no_payment_record':
                    chipColor = '#C9C9C9';
                    break;
                case 'succeeded':
                    chipColor = 'success';
                    break;
                case 'failed':
                    chipColor = 'error';
                    break;
                case 'refunded':
                    chipColor = 'info';
                    break;
            }

            return chipColor;
        },
        containsInvalidCharacters(x: string): boolean {
            let regex = new RegExp("[^A-Za-z0-9-_.!~*'() %\u4E00-\u9FFF]");
            return regex.test(x);
        },
        copyText(text: string): void {
            navigator.clipboard.writeText(text).then(
                () => {
                    store.dispatch('setSuccessMessage', {
                        message: i18n.t('instruction.success-text', { name: i18n.t('common.copy') }),
                        redirect: '',
                        refresh: false,
                    });
                },
                () => {
                    store.dispatch('setErrorMessage', {
                        message: i18n.t('instruction.fail-text', { name: i18n.t('common.copy') }),
                        redirect: '',
                        refresh: false,
                    });
                },
            );
        },
        calculateTableActionIndex(index: number, currentLimit: number, currentPage: number): number {
            if (currentLimit === -1) {
                return index;
            } else {
                return (currentPage - 1) * currentLimit + index;
            }
        },
        isChrome(): boolean {
            return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime); // Chrome 1 - 79
        },
        isFirefox(): boolean {
            return typeof InstallTrigger !== 'undefined'; // Firefox 1+
        },
        isIE(): boolean {
            const ua = navigator.userAgent;
            return document.documentMode && !/Edge/.test(ua); // IE 6-11
        },
        isEdge(): boolean {
            return !isIE && !!window.StyleMedia; // Edge 20+
        },
        isSafari(): boolean {
            return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)); // Safari 3.0+ "[object HTMLElementConstructor]"
        },
        getCurrentLang(zh_name: string, en_name: string = "", cn_name: string = "") {
            let result = zh_name;
            const lang = i18n.locale;

            if (lang === "en") {
                if (en_name !== undefined && en_name !== null && en_name !== '') {
                    result = en_name;
                }

            } if (lang === "cn") {
                if (cn_name !== undefined && cn_name !== null && cn_name !== '') {
                    result = cn_name;
                }
            }

            return result;
        },
        getMediaInsideHTMLString(htmlStr: string): Array<string> {
            let arr = []

            const dom = new DOMParser().parseFromString(htmlStr, "text/html");

            const imgTags = dom.getElementsByTagName('img');

            for (let i = 0; i < imgTags.length; i++) {
                const fullPath = imgTags[i].getAttribute('src').trim();

                arr.push(fullPath.substring(fullPath.indexOf("/media/") + 7));
            }

            const iframeTags = dom.getElementsByTagName("iframe");
            for (let i = 0; i < iframeTags.length; i++) {
                const fullPath = iframeTags[i].getAttribute('src').trim();

                arr.push(fullPath.substring(fullPath.indexOf("/media/") + 7));
            }

            return arr;
        }
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $common: {
            log(value: any): void,
            getMediaPath(): string,
            getPlanChipColor(type: string): string,
            getOrderStatusChipColor(type: string): string,
            getOrderStatusChipColor(type: string): string,
            containsInvalidCharacters(x: string): boolean,
            copyText(text: string): void,
            calculateTableActionIndex(index: number, currentLimit: number, currentPage: number): number,
            isChrome(): boolean,
            isFirefox(): boolean,
            isIE(): boolean,
            isEdge(): boolean,
            isSafari(): boolean,
            getCurrentLang(zh_name: string, en_name: string, cn_name: string): string,
            getMediaInsideHTMLString(htmlStr: string): Array<string>
        }
    }
}
