var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c(_vm.resolveLayout,{tag:"component"},[_c('router-view')],1)],1),_c('v-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.overlay),expression:"overlay"}],attrs:{"z-index":"999"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-snackbar',{staticClass:"app-snack",attrs:{"value":_vm.uploadProgress > 0,"top":"","right":"","app":"","timeout":"-1","transition":"scroll-y-transition"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2 snackbar-success"},[_vm._v(" mdi-tray-arrow-up ")]),_vm._v(" "+_vm._s(_vm.$t('common.uploading'))+"... ("+_vm._s(_vm.uploadProgress)+"%) ")],1)]),(_vm.showTrialBox && this.$vuetify.breakpoint.width >= 500 && _vm.$route.name !== 'POS')?_c('v-card',{staticClass:"trial-card",attrs:{"id":"trial-card","flat":"","width":_vm.trialCardWidth}},[_c('v-card-text',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: `primary--text` }),expression:"{ class: `primary--text` }"}],staticClass:"trial-card-title",style:({ 'font-size': _vm.expandTrialCard ? '1.125rem' : '0.875rem' }),on:{"click":function($event){_vm.expandTrialCard = !_vm.expandTrialCard}}},[_c('div',[_vm._v(_vm._s(_vm.$t('user.trial-start')))]),_c('div',{staticClass:"collapse-icon",style:({
          width: _vm.expandTrialCard ? '38px' : 'auto',
          height: _vm.expandTrialCard ? '38px' : 'auto',
          'background-color': _vm.expandTrialCard ? '#144162' : 'var(--v-secondary-base)',
        })},[_c('v-icon',{class:{
            'fi fi-rr-angle-square-down': !_vm.expandTrialCard,
            'fi fi-rr-angle-square-up': _vm.expandTrialCard,
          }})],1)]),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandTrialCard),expression:"expandTrialCard"}],staticStyle:{"margin":"0 !important"}},[_c('v-col',{staticStyle:{"padding":"0 !important"}},[_c('div',{staticClass:"item-wrapper"},[_c('div',{staticClass:"trial-text"},[_vm._v(" "+_vm._s(_vm.$t('user.trial-expired-date', { year: _vm.year, month: _vm.month, date: _vm.date }))+" ")]),_c('v-btn',{staticClass:"trial-btn",attrs:{"depressed":""}},[_vm._v(" "+_vm._s(_vm.$t('common.select-text', { name: _vm.$t('admin.plan') }))+" ")])],1)])],1)],1)],1):_vm._e(),_vm._l((_vm.snackbar),function(item,index){return _c('v-snackbar',{key:item.id,staticClass:"app-snack",style:(item.top
        ? `transform: translateY(calc(0px + ${100 * index}% + ${_vm.trialBoxHeight}px));`
        : `transform: translateY(calc(0px - ${100 * index}% - ${_vm.trialBoxHeight}px));`),attrs:{"max-width":"90%","min-width":_vm.$vuetify.breakpoint.width >= 400 ? '344px' : '90%',"bottom":!item.top,"top":item.top,"right":"","app":"","timeout":_vm.timeout,"transition":item.top ? 'scroll-y-reverse-transition' : 'scroll-y-transition'},on:{"input":function($event){return _vm.updateSnackbar(item.id)}},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.updateSnackbar(item.id)}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true),model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}},[_c('div',{staticClass:"d-flex align-center"},[(item.level === 'success')?_c('v-icon',{staticClass:"mr-2 snackbar-success"},[_vm._v(" mdi-check-circle ")]):(item.level === 'error')?_c('v-icon',{staticClass:"mr-2 snackbar-error"},[_vm._v(" mdi-alert-octagon ")]):(item.level === 'warning')?_c('v-icon',{staticClass:"mr-2 snackbar-warning"},[_vm._v(" mdi-alert ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v(" mdi-information ")]),_vm._v(" "+_vm._s(item.text)+" ")],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }