//@ts-nocheck 

import _Vue from 'vue';
import { jsPDF } from "jspdf"

let regularFont = ""
let boldFont = ""
import("@/assets/fonts/pdf-fonts/NotoSansTC-Regular-normal.js").then((font) => {
    regularFont = font.regularFont
});

import("@/assets/fonts/pdf-fonts/NotoSansTC-Medium-bold.js").then((font) => {
    boldFont = font.boldFont
});

export function PDFFunc(Vue: typeof _Vue): void {
    Vue.prototype.$jsPDF = {
        getPDFObj() {
            const pdf = new jsPDF();
            pdf.addFileToVFS('NotoSansTC-Regular-normal.ttf', regularFont);
            pdf.addFont('NotoSansTC-Regular-normal.ttf', 'NotoSansTC-Regular', 'normal');

            pdf.addFileToVFS('NotoSansTC-Medium-bold.ttf', boldFont);
            pdf.addFont('NotoSansTC-Medium-bold.ttf', 'NotoSansTC-Medium', 'bold');
            return pdf;
        }
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $jsPDF: {
            getPDFObj(): any
        }
    }
}
