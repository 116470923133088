
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import defaultIcon from '@/assets/icon/amazing_jackie_logo.svg';
import NavDrawer from '@/components/NavDrawer/NavDrawer.vue';
import NavListItem from '@/components/NavDrawer/NavListItem.vue';
import StoreSettingPanel from '@/components/StoreSettingPanel.vue';
import type StoreBasicSettingInterface from '@/interfaces/StoreBasicSettingInterface';
import ENV_SETTING from 'config';

@Component({ components: { NavDrawer, NavListItem, StoreSettingPanel } })
export default class Content extends Vue {
  // data
  userIcon: string = defaultIcon;
  ajIcon: string = defaultIcon;
  userName: string = 'Master';
  isUserMenuOpen: boolean = false;
  miniDrawer: boolean = false;
  isDrawerOpen: boolean = false;
  disableCloseMenu: boolean = false;
  drawerItem: Array<any> = [];
  userNavActionPanel: Array<any> = [];
  icon = {
    mdiChevronDown,
    mdiChevronUp,
  };
  openLangMenu: boolean = false;

  internalDataInterval: any = null;
  accountDataInterval: any = null;
  websiteDataInterval: any = null;
  purchasedPlanDataInterval: any = null;
  storeStaffDataInterval: any = null;

  isUser: boolean = false;
  storeDisabled: boolean = true;
  showBackToAdminButton: Boolean = false;
  checkInterval: number = 1000 * 60 * 5; // 5 minutes

  basicSettingProgress: StoreBasicSettingInterface = {
    language: false,
    currency: false,
    paymentSetting: false,
    deliverySetting: false,
    icon: false,
    product: false,
    customDomain: false,
  };
  startUpdateBasicSetting: boolean = false;

  get triggerBasicSetting(): boolean {
    return this.$store.getters['ConfigModule/basicSetting'];
  }

  get btnLength(): any {
    return document.getElementById('hover-btn')?.offsetWidth;
  }

  get showShopGuide(): boolean {
    if (this.$validator.DataValid(ENV_SETTING.HIDE_SHOP_GUIDE_BTN)) {
      return !ENV_SETTING.HIDE_SHOP_GUIDE_BTN;
    }

    return true;
  }

  get showViewStoreBtn(): boolean {
    if (this.$validator.DataValid(ENV_SETTING.HIDE_VIEW_STORE_BTN)) {
      return !ENV_SETTING.HIDE_VIEW_STORE_BTN;
    }

    return true;
  }

  get hideStoreSettingPanel(): boolean {
    // true = hide the setting panel

    if (this.isUser) {
      let check: any = {};
      Object.assign(check, this.basicSettingProgress);
      delete check.customDomain;
      const settingFinish = Object.values(check).every(val => val === true);

      if (settingFinish) {
        return true;
      } else {
        // hide when current route is POS page
        return this.$route.name === 'POS';
      }
    }

    return true;
  }

  get applicationKey() {
    return this.$store.getters['ConfigModule/applicationKey'];
  }

  get mainContentClass() {
    return {
      'pos-container pt-5 pb-2 px-2': this.$route.name === 'POS',
      'app-content-container boxed-container py-6 px-3 px-sm-6 px-md-6 px-lg-6 px-xl-6': this.$route.name !== 'POS',
      'mb-16': this.getShowTrialBox() && this.$vuetify.breakpoint.width >= 500 && this.$route.name !== 'POS',
    };
  }

  get storeWebsiteData() {
    return this.getWebsiteData();
  }

  getShowTrialBox(): Boolean {
    return this.$store.getters['ConfigModule/showTrialBox'];
  }

  /** Determine where the update basic setting progress function should start */
  @Watch('triggerBasicSetting')
  start(status: boolean): void {
    if (status) {
      this.updateBasicSettingProgress();
    }
  }

  @Watch('applicationKey')
  toggleCustomerView(key: string) {
    if (this.$validator.DataValid(key)) {
      // Customer View

      this.isUser = true;

      this.showBackToAdminButton = this.checkInternalAccountExist();

      if (this.triggerBasicSetting) {
        this.updateBasicSettingProgress();
      }

      this.startCheckCustomerData();
    } else {
      // Admin View
      this.isUser = false;
      this.showBackToAdminButton = false;
      this.userName = 'Master';
      this.userIcon = this.ajIcon;
      this.startCheckingAdminData();

      this.clearAccountDataInterval();
      this.clearWebsiteDataInterval();
      this.clearPurchasedPlanDataInterval();
    }
  }

  // methods
  openStoreWebsiteLink() {
    const accountData = this.getStoreAccountData();
    if (this.$validator.DataValid(accountData)) {
      window.open(`https://${accountData.sub_domain}`);
    } else {
      window.open(`https://new.amazingjackie.com/store_website_projects/${accountData.id}`);
    }
  }

  changeLang(type: string) {
    const currentLang = this.$root.$i18n.locale;

    if (type !== currentLang) {
      this.$root.$i18n.locale = type;

      document.documentElement.setAttribute('lang', type);
      this.$store.dispatch('ConfigModule/setLocale', type);
      this.$router.go(0);
    }
  }

  // for master admin
  startCheckingAdminData() {
    if (this.checkInternalAccountExist()) {
      this.storeDisabled = true;
      this.startCheckingInternalAccount();

      // render drawer
      this.drawerItem = this.getInternalAccountDrawer();

      // render nav panel
      this.userNavActionPanel = this.getAdminNavActionPanel();

      this.internalDataInterval = setInterval(() => {
        this.startCheckingInternalAccount();
      }, this.checkInterval);
    }
  }

  getInternalAccountDrawer(): Array<Object> {
    return [
      {
        isGroup: false,
        show: true,
        text: 'menu-admin.staff-store-list',
        linkName: 'StaffStoreList',
        icon: 'fi fi-rr-meh',
      },
      {
        isGroup: false,
        show: true,
        text: 'menu-admin.fourteen-expire',
        linkName: 'Login',
        icon: 'fi fi-rr-shield-exclamation',
      },
      {
        isGroup: true,
        show: true,
        text: 'menu-admin.order',
        icon: 'fi fi-rr-donate',
        items: [
          /*           {
            text: "menu-admin.staff-store-list",
            linkName: "StaffStoreList",
            icon: "fi fi-rr-meh",
          }, */
          {
            show: true,
            text: 'menu-admin.staff-store-list',
            linkName: 'Login',
          },
        ],
      },
      {
        isGroup: true,
        show: true,
        text: 'menu-admin.promotion',
        icon: 'fi fi-rr-gift',
        items: [
          {
            show: true,
            text: 'menu-admin.staff-store-list',
            linkName: 'Login',
          },
          {
            show: true,
            text: 'menu-admin.staff-store-list',
            linkName: 'Login',
          },
        ],
      },
      {
        isGroup: true,
        show: true,
        text: 'menu-admin.worker-account',
        icon: 'fi fi-rr-users-alt',
        items: [
          {
            show: true,
            text: 'menu-admin.staff-store-list',
            linkName: 'Login',
          },
          {
            show: true,
            text: 'menu-admin.staff-store-list',
            linkName: 'Login',
          },
        ],
      },
      {
        isGroup: false,
        show: true,
        text: 'menu-admin.log',
        linkName: 'Login',
        icon: 'fi fi-rr-clock-seven',
      },
    ];
  }

  getAdminNavActionPanel(): Array<any> {
    return [];
  }

  async startCheckingInternalAccount() {
    let error = true;
    if (this.checkInternalAccountExist()) {
      const internalData: any = this.getInternalAccountData();
      if (this.$validator.DataValid(internalData.id) && this.$validator.DataValid(internalData.data_type)) {
        try {
          const payload = {
            data_type: internalData.data_type,
            id: internalData.id,
          };

          let result = await this.$XHR.api('get_single_data_by_data_type_and_id', payload);
          this.$common.log('---check internal data success---');
          this.$common.log(result);
          error = false;
          this.setInternalAccountData(result);
        } catch (error) {
          this.$common.log('---check internal data fail---');
          this.$common.log(error);
        }
      }
    }

    if (error) {
      this.$store.dispatch('setErrorMessage', {
        message: this.$t('instruction.login-expired'),
        redirect: '',
        refresh: false,
      });

      this.logoutAccount();
    }
  }

  // for user
  startCheckCustomerData() {
    if (this.checkStoreAccountExist()) {
      this.isUser = true;

      this.startCheckingStoreAccount();

      // render Drawer
      this.renderUserDrawerItem();

      // render nav panel
      this.userNavActionPanel = this.getUserNavActionPanel();
      this.showBackToAdminButton = this.checkInternalAccountExist();

      this.accountDataInterval = setInterval(() => {
        this.startCheckingStoreAccount();
      }, this.checkInterval);
    }

    if (this.checkWebsiteDataExist()) {
      this.startCheckingWebsiteData();
      const websiteData = this.getWebsiteData();

      if (this.$validator.DataValid(websiteData.logo_img_url)) {
        this.userIcon = this.$common.getMediaPath() + websiteData.logo_img_url;
      }

      this.websiteDataInterval = setInterval(() => {
        this.startCheckingWebsiteData();
      }, this.checkInterval);
    }

    if (this.checkPurchasedPlanDataExist()) {
      this.startCheckingPurchasedPlanData();

      this.purchasedPlanDataInterval = setInterval(() => {
        this.startCheckingPurchasedPlanData();
      }, this.checkInterval);
    }

    if (this.checkStoreStaffDataExist()) {
      this.startCheckingStoreStaffData();

      this.storeStaffDataInterval = setInterval(() => {
        this.startCheckingStoreStaffData();
      }, this.checkInterval);
    }
  }

  getUserDrawer(): Array<any> {
    return [
      {
        isGroup: false,
        show: false,
        text: 'menu-user.my-store',
        linkName: 'MyStore',
        id: 'my-store',
        icon: 'fi fi-rr-tachometer-fast',
      },
      {
        isGroup: false,
        show: false,
        text: 'menu-user.pos',
        linkName: 'POS',
        id: 'pos',
        icon: 'fi-rr-piggy-bank',
      },
      {
        isGroup: true,
        show: false,
        id: 'layout',
        text: 'menu-user.layout-setting',
        icon: 'fi fi-br-layers',
        items: [
          {
            show: true,
            text: 'menu-user.home-banner',
            linkName: 'HomeDesign',
          },
          {
            show: true,
            text: 'menu-user.menu-management',
            linkName: 'MenuManagement',
          },
          {
            show: true,
            text: 'menu-user.custom-page-content',
            linkName: 'CustomPage',
          },
        ],
      },
      {
        isGroup: false,
        show: false,
        text: 'menu-user.member-management',
        linkName: 'MemberList',
        id: 'user',
        icon: 'fi fi-rr-grin-alt',
      },
      {
        isGroup: true,
        show: false,
        id: 'product',
        text: 'menu-user.product',
        icon: 'fi fi-rr-dice-d6',
        items: [
          {
            show: true,
            text: 'menu-user.all-product',
            linkName: 'ProductList',
          },
          {
            show: true,
            text: 'menu-user.product-inventory',
            linkName: 'ProductInventoryList',
          },
          {
            show: true,
            text: 'menu-user.add-on-product',
            linkName: 'AddOnProductList',
          },
          {
            show: true,
            text: 'menu-user.category-management',
            linkName: 'ProductCategory',
          },
        ],
      },
      {
        isGroup: true,
        show: false,
        id: 'course',
        text: 'menu-user.course',
        icon: 'fi fi-rr-graduation-cap',
        items: [
          {
            subID: 'yoga_course',
            show: false,
            text: 'menu-user.course-management',
            linkName: 'YogaCourseTimetable',
          },
          {
            subID: 'credit_package',
            show: false,
            text: 'menu-user.credit-order',
            linkName: 'YogaCreditPackageOrderList',
          },
          {
            subID: 'credit_package',
            show: false,
            text: 'menu-user.credit-setting',
            linkName: 'YogaCreditPackageList',
          },
        ],
      },
      {
        isGroup: false,
        show: false,
        id: 'order',
        text: 'menu-user.order',
        icon: 'fi fi-rr-donate',
        linkName: 'OrderList',
      },
      {
        isGroup: true,
        show: false,
        id: 'appointment',
        text: 'menu-user.appointment',
        icon: 'fi fi-rr-calendar-check',
        items: [
          {
            show: true,
            text: 'appointment.title',
            linkName: 'AppointmentList',
          },
          {
            show: true,
            text: 'appointment.appointment-record',
            linkName: 'AppointmentRecordList',
          },
          {
            show: true,
            text: 'appointment.appointment-category',
            linkName: 'AppointmentCategory',
          },
        ],
      },
      {
        isGroup: true,
        show: false,
        id: 'medi-appointment',
        text: 'menu-user.appointment',
        icon: 'fi fi-rr-calendar-check',
        items: [
          {
            show: true,
            text: 'appointment.title',
            linkName: 'MediAppointmentList',
          },
          {
            show: true,
            text: 'appointment.appointment-record',
            linkName: 'MediAppointmentRecordList',
          },
          {
            show: true,
            text: 'appointment.appointment-category',
            linkName: 'MediAppointmentCategory',
          },
        ],
      },
      {
        isGroup: false,
        show: false,
        id: 'monthly_fee',
        text: 'monthly-fee.title',
        icon: 'fi fi-rr-file-invoice-dollar',
        linkName: 'MonthlyFeeRecord',
      },
      {
        isGroup: false,
        show: false,
        id: 'promotion',
        text: 'menu-user.promotion',
        icon: 'fi fi-rr-gift',
        linkName: 'PromotionList',
      },
      {
        isGroup: true,
        show: false,
        id: 'setting',
        text: 'menu-user.setting',
        icon: 'fi fi-rr-settings',
        items: [
          {
            show: true,
            text: 'menu-user.general-setting',
            linkName: 'GeneralSetting',
          },
          {
            show: true,
            text: 'menu-user.payment-setting',
            linkName: 'PaymentSetting',
          },
          {
            show: true,
            text: 'menu-user.delivery-setting',
            linkName: 'DeliverySetting',
          },
          {
            show: true,
            text: 'menu-user.promotion-setting',
            linkName: 'PromotionSetting',
          },
        ],
      },
      {
        isGroup: false,
        show: false,
        text: 'menu-user.enquiry',
        linkName: 'EnquiryList',
        id: 'enquiry',
        icon: 'fi fi-rr-mailbox',
      },
      {
        isGroup: false,
        show: false,
        text: 'menu-user.enquiry',
        linkName: 'MediEnquiryList',
        id: 'medi-enquiry',
        icon: 'fi fi-rr-mailbox',
      },
      {
        isGroup: false,
        show: false,
        text: 'store-staff-account.title',
        linkName: 'StoreStaffAccountList',
        id: 'store_staff_account',
        icon: 'fi fi-rr-users',
      },
      {
        isGroup: false,
        show: false,
        text: 'supplier.title',
        linkName: 'SupplierList',
        id: 'supplier',
        icon: 'fi fi-rr-boxes',
      },
      {
        isGroup: false,
        show: false,
        text: 'purchase-order.title',
        linkName: 'PurchaseOrderList',
        id: 'purchase_order',
        icon: 'fi fi-rr-truck-loading',
      },

      {
        isGroup: true,
        show: false,
        id: 'renting_service',
        text: 'renting.title',
        icon: 'fi fi-rr-handshake',
        items: [
          {
            show: true,
            text: 'renting.product-list',
            linkName: 'RentingProductList',
          },
          {
            show: true,
            text: 'renting.renting-record',
            linkName: 'RentingRecord',
          },
        ],
      },

      {
        isGroup: false,
        show: false,
        text: 'staff-log.title',
        linkName: 'StaffLog',
        id: 'staff_log',
        icon: 'fi fi-rr-clock-three',
      },

      {
        isGroup: false,
        show: false,
        text: 'push-notification.title',
        linkName: 'PushNotificationList',
        id: 'push_notification',
        icon: 'fi fi-rr-bell',
      },

      {
        isGroup: true,
        show: false,
        id: 'quotation',
        text: 'quotation.title',
        icon: 'fi fi-rr-money-check-edit',
        items: [
          {
            show: true,
            text: 'quotation.title',
            linkName: 'QuotationList',
          },
          {
            show: true,
            text: 'quotation.management',
            linkName: 'QuotationManagement',
          },
        ],
      },

      {
        isGroup: false,
        show: false,
        text: 'product-booking.title',
        linkName: 'ProductBookingList',
        id: 'product_booking',
        icon: 'fi fi-rr-calendar-check',
      },
    ];
  }

  renderUserDrawerItem(): void {
    const storeAccountData = this.getStoreAccountData();

    if (this.$validator.DataValid(storeAccountData.store_name)) {
      this.userName = storeAccountData.store_name;
    }

    const cmsSideMenu = storeAccountData.cms_side_menu;
    let moduleList: any = [];

    const planData = this.getPurchasedPlanData();

    if (this.$validator.DataValid(planData)) {
      moduleList = planData.plan_data.module_list;
    }

    const drawer = this.getUserDrawer();

    for (let i = 0; i < cmsSideMenu.length; i++) {
      const index = drawer.findIndex((obj: any) => {
        return obj.id === cmsSideMenu[i];
      });

      if (index !== -1) {
        if (cmsSideMenu[i] === 'course') {
          // for credit_package and yoga_course
          const containsCreditPackage = moduleList.includes('credit_package');
          const containsYogaCourse = moduleList.includes('yoga_course');

          if (!containsCreditPackage) {
            drawer[index].show = false;
          } else {
            drawer[index].show = true;

            const courseItem = drawer[index].items;

            for (let k = 0; k < courseItem.length; k++) {
              drawer[index].items[k].show = true;

              if (courseItem[k].subID === 'yoga_course') {
                if (!containsYogaCourse) {
                  drawer[index].items[k].show = false;
                }
              }
            }
          }
        } else {
          drawer[index].show = true;
        }
      }
    }

    for (let i = 0; i < moduleList.length; i++) {
      const index = drawer.findIndex((obj: any) => {
        return obj.id === moduleList[i];
      });

      if (index !== -1) {
        drawer[index].show = true;
      }
    }

    this.drawerItem = drawer;
  }

  getUserNavActionPanel(): Array<any> {
    let navList = [
      {
        text: 'menu-user.my-account',
        linkName: 'MyStore',
      },
      {
        text: 'menu-user.my-plan',
        linkName: 'Login',
      },
      {
        text: 'menu-user.bill',
        linkName: 'Login',
      },
      {
        text: 'menu-user.other-setting',
        linkName: 'Login',
      },
    ];

    return navList;
  }

  async startCheckingStoreAccount() {
    let error = true;
    if (this.checkStoreAccountExist()) {
      const accountData: any = this.getStoreAccountData();

      if (this.$validator.DataValid(accountData.application_key)) {
        try {
          const payload = {
            application_key: accountData.application_key,
          };

          let result = await this.$XHR.api('get_store_account_data_by_application_key', payload);
          this.$common.log('---check store account data success---');
          this.$common.log(result);
          error = false;

          this.setStoreAccountData(result);
        } catch (error) {
          this.$common.log('---check store account data fail---');
          this.$common.log(error);
        }
      }
    }

    if (error) {
      this.$store.dispatch('setErrorMessage', {
        message: this.$t('instruction.login-expired'),
        redirect: '',
        refresh: false,
      });

      this.logoutAccount();
    }
  }

  async startCheckingWebsiteData() {
    let error = true;
    if (this.checkWebsiteDataExist()) {
      const websiteData: any = this.getWebsiteData();

      if (this.$validator.DataValid(websiteData.application_key)) {
        try {
          const payload = {
            application_key: websiteData.application_key,
          };

          let result = await this.$XHR.api('get_store_website_data_by_application_key', payload);
          this.$common.log('---check store website data success---');
          this.$common.log(result);

          this.storeDisabled = result.disabled;
          error = false;

          this.setWebsiteData(result);
        } catch (error) {
          this.$common.log('---check store website data fail---');
          this.$common.log(error);
        }
      }
    }

    if (error) {
      this.$store.dispatch('setErrorMessage', {
        message: this.$t('instruction.login-expired'),
        redirect: '',
        refresh: false,
      });

      this.logoutAccount();
    }
  }

  async startCheckingPurchasedPlanData() {
    let error = true;
    if (this.checkPurchasedPlanDataExist()) {
      const planData: any = this.getPurchasedPlanData();

      if (this.$validator.DataValid(planData.application_key)) {
        try {
          const payload = {
            application_key: planData.application_key,
          };

          let result = await this.$XHR.api('get_current_purchased_plan_exluding_disabled_by_application_key', payload);
          this.$common.log('---check purchased plan data success---');
          this.$common.log(result);
          error = false;

          if (this.$validator.DataValid(result)) {
            if (result.store_payment_id === 'trial') {
              this.$store.dispatch('ConfigModule/setTrialBox', true);
            } else {
              this.$store.dispatch('ConfigModule/setTrialBox');
            }

            this.setPurchasedPlanData(result);
          }
        } catch (error) {
          this.$common.log('---check purchased plan data fail---');
          this.$common.log(error);
        }
      }
    }

    if (error) {
      this.$store.dispatch('setErrorMessage', {
        message: this.$t('instruction.login-expired'),
        redirect: '',
        refresh: false,
      });

      this.logoutAccount();
    }
  }

  async startCheckingStoreStaffData() {
    let error = true;
    if (this.checkStoreStaffDataExist()) {
      const staffData: any = this.getStoreStaffData();

      if (this.$validator.DataValid(staffData.application_key)) {
        try {
          const payload = {
            id: staffData.id,
            data_type: 'store_staff_data',
            application_key: staffData.application_key,
          };

          let result = await this.$XHR.api('get_single_data_by_data_type_and_application_key_and_id', payload);
          this.$common.log('---check store staff data success---');
          this.$common.log(result);

          if (!result.disabled) {
            error = false;
            this.setStoreStaffData(result);
          }
        } catch (error) {
          this.$common.log('---check store staff data fail---');
          this.$common.log(error);
        }
      }
    }

    if (error) {
      this.$store.dispatch('setErrorMessage', {
        message: this.$t('instruction.login-expired'),
        redirect: '',
        refresh: false,
      });

      this.logoutAccount();
    }
  }

  async updateBasicSettingProgress() {
    if (!this.startUpdateBasicSetting) {
      this.$common.log('---start checking basic setting progress---');
      this.startUpdateBasicSetting = true;

      const accountData = this.getStoreAccountData();

      if (this.$validator.DataValid(accountData)) {
        this.basicSettingProgress.language = accountData.accept_language.length > 0;
        this.basicSettingProgress.currency = accountData.accept_currency.length > 0;
        this.basicSettingProgress.paymentSetting = accountData.payment_method_list.length > 0;
        this.basicSettingProgress.deliverySetting = accountData.delivery_method_list.length > 0;

        try {
          const payload = {
            sorting: 'create_date',
            direction: 'DESC',
            limit: 1,
            page: 1,
            application_key: accountData.application_key,
            filter_additional_product: false,
            merge_with_quantity: false,
            include_pos: true,
          };

          const result = await this.$XHR.api(
            'get_product_data_and_total_number_by_sorting_and_limit_merge_with_order_quantity',
            payload,
          );
          this.$common.log('---get product data (checking basic setting)---');
          this.$common.log(result);

          if (!Array.isArray(result)) {
            if (this.$validator.DataValid(result.total_number)) {
              this.basicSettingProgress.product = result.total_number > 0;
            }
          } else {
            this.basicSettingProgress.product = false;
          }
        } catch (error) {
          this.$common.log('---get product data fail (checking basic setting)---');
          this.$common.log(error);
          this.basicSettingProgress.product = false;
        }
      }

      const websiteData = this.getWebsiteData();
      if (this.$validator.DataValid(websiteData)) {
        this.basicSettingProgress.icon = this.$validator.DataValid(websiteData.logo_img_url);
      }

      this.$common.log(this.basicSettingProgress);
      this.startUpdateBasicSetting = false;
      this.$common.log('---checking basic setting progress finish---');
    }
  }

  // Clear Interval
  clearInternalDataInterval() {
    if (this.$validator.DataValid(this.internalDataInterval)) {
      this.$common.log('---clear internal data interval---');
      clearInterval(this.internalDataInterval);
    }
  }

  clearAccountDataInterval() {
    if (this.$validator.DataValid(this.accountDataInterval)) {
      this.$common.log('---clear store account data interval---');
      clearInterval(this.accountDataInterval);
    }
  }

  clearWebsiteDataInterval() {
    if (this.$validator.DataValid(this.websiteDataInterval)) {
      this.$common.log('---clear website data interval---');
      clearInterval(this.websiteDataInterval);
    }
  }

  clearPurchasedPlanDataInterval() {
    if (this.$validator.DataValid(this.purchasedPlanDataInterval)) {
      this.$common.log('---clear purchased plan data interval---');
      clearInterval(this.purchasedPlanDataInterval);
    }
  }

  clearStoreStaffDataInterval() {
    if (this.$validator.DataValid(this.storeStaffDataInterval)) {
      this.$common.log('---clear store staff data interval---');
      clearInterval(this.storeStaffDataInterval);
    }
  }

  // lifecycle
  created() {
    this.$i18n.locale = this.$store.getters['ConfigModule/locale'];
    if (this.triggerBasicSetting) {
      this.updateBasicSettingProgress();
    }
  }

  mounted() {
    this.isUser = false;
    this.storeDisabled = true;
    this.userName = 'Master';
    this.userIcon = this.ajIcon;
    this.startCheckingAdminData();
    this.startCheckCustomerData();
  }

  beforeDestroy() {
    this.clearInternalDataInterval();
    this.clearAccountDataInterval();
    this.clearWebsiteDataInterval();
    this.clearPurchasedPlanDataInterval();
    this.clearStoreStaffDataInterval();
  }
}
