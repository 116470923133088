
import { Vue, Component, Prop } from 'vue-property-decorator';
import type StoreBasicSettingInterface from '@/interfaces/StoreBasicSettingInterface';

@Component
export default class StoreSettingPanel extends Vue {
  @Prop({ type: Object, required: true })
  basicSettingProgress!: StoreBasicSettingInterface;
  expand: boolean = false;
  progressPercentage: number = 0;

  get progressValue(): number {
    let count = 0;
    let val = Object.values(this.basicSettingProgress);

    for (let i = 0; i < val.length; i++) {
      if (val[i]) {
        count++;
      }
    }

    this.progressPercentage = (count / this.totalCount) * 100;

    return count;
  }

  get totalCount(): number {
    return Object.keys(this.basicSettingProgress).length;
  }

  // methods
  redirectPage(name: string) {
    if (this.$validator.DataValid(name)) {
      if (name !== this.$route.name) {
        this.expand = false;
        this.$router.push({ name });
      }
    }
  }
}
