// import Cookies from 'js-cookie';
import { Vue, Component } from 'vue-property-decorator';
var CryptoJS = require("crypto-js");

import type InternalAccountDataInterface from '@/interfaces/InternalAccountDataInterface';

// types.d.ts

@Component
export default class AuthMixin extends Vue {
    private INTERNAL_ACCOUNT_KEY = 'aj_cms_internal_account_data';
    private STORE_ACCOUNT_KEY = 'aj_cms_store_account_data';
    private STORE_WEBSITE_KEY = 'aj_cms_store_website_data';
    private STORE_STAFF_KEY = 'aj_cms_store_staff_data';
    private PURCHASED_PLAN_KEY = 'aj_cms_purchased_plan_data';


    /*     private cookiesSetting: Cookies.CookieAttributes = {
            path: '/',
            expires: 1, // expires after 1 day
            sameSite: 'strict',
            secure: true, // 
        } */

    private encryptString(str: string): string {
        return CryptoJS.AES.encrypt(str, process.env.VUE_APP_API_ENCRYPTION_KEY);
    }

    private decryptString(str: any): string {
        if (this.$validator.DataValid(str)) {
            const decrypt = CryptoJS.AES.decrypt(str, process.env.VUE_APP_API_ENCRYPTION_KEY);
            return decrypt.toString(CryptoJS.enc.Utf8);
        }

        return '{}'
    }

    private getCurrentTime(): number {
        return new Date().getTime();
    }

    public setInternalAccountData(accountData: InternalAccountDataInterface): void {
        if (this.$validator.DataValid(accountData.password_data)) {
            delete accountData.password_data;
        }

        accountData["currentTime"] = this.getCurrentTime();

        const encryptData = this.encryptString(JSON.stringify(accountData));

        localStorage.setItem(this.INTERNAL_ACCOUNT_KEY, encryptData.toString());
    }

    public getInternalAccountData(): InternalAccountDataInterface {
        const decryptData = this.decryptString(localStorage.getItem(this.INTERNAL_ACCOUNT_KEY));
        return JSON.parse(decryptData ?? '{}');
    }

    public checkInternalAccountExist(): Boolean {
        return this.$validator.DataValid(this.getInternalAccountData());
    }

    public setStoreAccountData(accountData: any): void {
        if (this.$validator.DataValid(accountData.website_data)) {
            const websiteData = JSON.parse(JSON.stringify(accountData.website_data));
            delete accountData.website_data;
            this.setWebsiteData(websiteData);
        }

        if (this.$validator.DataValid(accountData.store_staff_data)) {
            const storeStaffData = JSON.parse(JSON.stringify(accountData.store_staff_data));
            delete accountData.store_staff_data;
            this.setStoreStaffData(storeStaffData);
        }

        if (this.$validator.DataValid(accountData.latest_plan)) {
            const planData = JSON.parse(JSON.stringify(accountData.latest_plan));
            delete accountData.latest_plan;
            this.setPurchasedPlanData(planData);
        }

        const currentTime = this.getCurrentTime();
        accountData["currentTime"] = currentTime;
        const encryptData = this.encryptString(JSON.stringify(accountData));
        localStorage.setItem(this.STORE_ACCOUNT_KEY, encryptData.toString());
        this.$store.dispatch("ConfigModule/saveApplicationKey", accountData.application_key);
    }

    public getStoreAccountData() {
        const decryptData = this.decryptString(localStorage.getItem(this.STORE_ACCOUNT_KEY));
        return JSON.parse(decryptData ?? '{}');
    }

    public checkStoreAccountExist(): Boolean {
        return this.$validator.DataValid(this.getStoreAccountData());
    }

    public setWebsiteData(websiteData: any) {
        const currentTime = this.getCurrentTime();
        websiteData["currentTime"] = currentTime;

        const encryptWebsiteData = this.encryptString(JSON.stringify(websiteData));
        localStorage.setItem(this.STORE_WEBSITE_KEY, encryptWebsiteData.toString());
        this.$store.dispatch("ConfigModule/startBasicSettingProgress", true);
    }

    public getWebsiteData() {
        const decryptData = this.decryptString(localStorage.getItem(this.STORE_WEBSITE_KEY));
        return JSON.parse(decryptData ?? '{}');
    }

    public checkWebsiteDataExist() {
        return this.$validator.DataValid(this.getWebsiteData());
    }

    public setStoreStaffData(staffData: any) {
        const currentTime = this.getCurrentTime();
        staffData["currentTime"] = currentTime;

        const encryptStaffData = this.encryptString(JSON.stringify(staffData));
        localStorage.setItem(this.STORE_STAFF_KEY, encryptStaffData.toString());
    }

    public getStoreStaffData() {
        const decryptData = this.decryptString(localStorage.getItem(this.STORE_STAFF_KEY));
        return JSON.parse(decryptData ?? '{}');
    }

    public checkStoreStaffDataExist() {
        return this.$validator.DataValid(this.getStoreStaffData());
    }

    public setPurchasedPlanData(planData: any) {
        const currentTime = this.getCurrentTime();
        planData["currentTime"] = currentTime;

        const encryptPurchasedPlanData = this.encryptString(JSON.stringify(planData));
        localStorage.setItem(this.PURCHASED_PLAN_KEY, encryptPurchasedPlanData.toString());
    }

    public getPurchasedPlanData() {
        const decryptData = this.decryptString(localStorage.getItem(this.PURCHASED_PLAN_KEY));
        return JSON.parse(decryptData ?? '{}');
    }

    public checkPurchasedPlanDataExist() {
        return this.$validator.DataValid(this.getPurchasedPlanData());
    }

    public exitStoreView() {
        localStorage.removeItem(this.STORE_ACCOUNT_KEY);
        localStorage.removeItem(this.STORE_WEBSITE_KEY);
        localStorage.removeItem(this.PURCHASED_PLAN_KEY);
        localStorage.removeItem(this.STORE_STAFF_KEY);
        this.$store.dispatch("ConfigModule/startBasicSettingProgress", false);
        this.$store.dispatch("ConfigModule/saveApplicationKey", "");
        this.$store.dispatch("ConfigModule/setTrialBox", false);
    }

    public logoutAccount() {
        this.exitStoreView();
        localStorage.removeItem(this.INTERNAL_ACCOUNT_KEY);
        this.$router.replace({ name: 'Login' });
    }
}