import type { NavigationGuardNext, Position, PositionResult, Route, RouteConfig } from 'vue-router/types/router';
import type { VuetifyGoToTarget } from 'vuetify/types/services/goto';
import goTo from 'vuetify/lib/services/goto';
import VueRouter from 'vue-router';
import Vue from 'vue';
import i18n from '@/plugins/i18n';
// import Cookies from 'js-cookie'.

import Error from '@/pages/Error.vue';
Vue.use(VueRouter);

const vm = new Vue();

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: ['common.login'],
      skipAuth: true,
      requireAdminAuth: false,
      requireUserAuth: false,
    },
  },

  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('@/pages/ForgetPassword.vue'),
    meta: {
      layout: 'blank',
      title: ['common.forget-pw'],
      skipAuth: true,
      requireAdminAuth: false,
      requireUserAuth: false,
    },
  },

  {
    path: '/reset-password/:id/:token',
    name: 'ResetPassword',
    component: () => import('@/pages/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      title: ['common.reset-pw'],
      skipAuth: true,
      adminAllow: true,
      requireAdminAuth: false,
      requireUserAuth: false,
    },
  },

  // admin
  {
    path: '/staff-store-list',
    name: 'StaffStoreList',
    component: () => import('@/pages/admin/StaffStoreList.vue'),
    meta: {
      title: ['menu-admin.staff-store-list'],
      requireAdminAuth: true,
    },
  },
  {
    path: '/staff-store-list/purchase-plan-record/:appKey',
    name: 'PurchasePlanDetail',
    component: () => import('@/pages/admin/PurchasePlanRecord.vue'),
    meta: {
      title: ['admin.store-purchase-record'],
      requireAdminAuth: true,
    },
  },
  {
    path: '/staff-store-list/store-setting/add',
    name: 'AddNewStore',
    component: () => import('@/pages/admin/StoreSetting.vue'),
    meta: {
      title: ['common.add-new', { name: 'admin.store' }],
      requireAdminAuth: true,
    },
  },
  {
    path: '/staff-store-list/store-setting/:appKey',
    name: 'StoreSetting',
    component: () => import('@/pages/admin/StoreSetting.vue'),
    meta: {
      title: ['common.setting-text', { name: 'admin.store' }],
      requireAdminAuth: true,
    },
  },
  {
    path: '/staff-store-list/order-plan/add/:appKey',
    name: 'OrderNewPlan',
    component: () => import('@/pages/admin/PlanOrder.vue'),
    meta: {
      title: ['common.add-new', { name: 'admin.order' }],
      isAdd: true,
      requireAdminAuth: true,
    },
  },
  {
    path: '/staff-store-list/order-plan/:appKey/:purchasedPlanID',
    name: 'OrderPlanDetail',
    component: () => import('@/pages/admin/PlanOrder.vue'),
    meta: {
      title: ['common.detail-text', { name: 'admin.order' }],
      isAdd: false,
      requireAdminAuth: true,
    },
  },

  // user
  {
    path: '/my-store',
    name: 'MyStore',
    component: () => import('@/pages/user/MyStore.vue'),
    meta: {
      title: ['menu-user.my-store'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/product/pos',
    name: 'POS',
    component: () => import('@/pages/user/POS.vue'),
    meta: {
      title: ['menu-user.pos'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/layout/home-design',
    name: 'HomeDesign',
    component: () => import('@/pages/user/layout-setting/HomeDesign.vue'),
    meta: {
      title: ['menu-user.home-banner'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/layout/menu-management',
    name: 'MenuManagement',
    component: () => import('@/pages/user/layout-setting/MenuManagement.vue'),
    meta: {
      title: ['menu-user.menu-management'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/layout/custom-page',
    name: 'CustomPage',
    component: () => import('@/pages/user/layout-setting/CustomPage.vue'),
    meta: {
      title: ['menu-user.custom-page-content'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/layout/custom-page/add',
    name: 'AddCustomPage',
    component: () => import('@/pages/user/layout-setting/CustomPageDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'custom-page.custom' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/layout/custom-page/detail/:id',
    name: 'CustomPageDetail',
    component: () => import('@/pages/user/layout-setting/CustomPageDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'custom-page.custom' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/member/member-list',
    name: 'MemberList',
    component: () => import('@/pages/user/member/MemberList.vue'),
    meta: {
      title: ['menu-user.member-management'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/member/member-list/add',
    name: 'AddMember',
    component: () => import('@/pages/user/member/MemberDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'menu-user.member-management' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/member/member-list/detail/:id',
    name: 'MemberDetail',
    component: () => import('@/pages/user/member/MemberDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'menu-user.member-management' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/member/member-list/cash-dollar-record/:id',
    name: 'CashDollarChangeRecord',
    component: () => import('@/pages/user/member/CashDollarChangeRecord.vue'),
    meta: {
      title: ['member.cash-dollar-record'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/member/member-list/yoga-course-record/:id',
    name: 'ApplyYogaCourseRecord',
    component: () => import('@/pages/user/member/ApplyYogaCourseRecord.vue'),
    meta: {
      title: ['yoga-course.course-apply-record'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/product/product-inventory-list',
    name: 'ProductInventoryList',
    component: () => import('@/pages/user/product/ProductInventoryList.vue'),
    meta: {
      title: ['menu-user.product-inventory'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/product/product-list',
    name: 'ProductList',
    component: () => import('@/pages/user/product/ProductList.vue'),
    meta: {
      title: ['menu-user.product'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
    props: true,
  },
  {
    path: '/product/product-list/add',
    name: 'AddProduct',
    component: () => import('@/pages/user/product/ProductDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'menu-user.product' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/product/product-list/detail/:productCode',
    name: 'ProductDetail',
    component: () => import('@/pages/user/product/ProductDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'menu-user.product' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/product/add-on-product-list',
    name: 'AddOnProductList',
    component: () => import('@/pages/user/product/AddOnProductList.vue'),
    meta: {
      title: ['menu-user.add-on-product'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/product/add-on-product-list/add',
    name: 'AddAddOnProduct',
    component: () => import('@/pages/user/product/AddOnProductDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'menu-user.add-on-product' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/product/add-on-product-list/:id',
    name: 'AddOnProductDetail',
    component: () => import('@/pages/user/product/AddOnProductDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'menu-user.add-on-product' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/product/category',
    name: 'ProductCategory',
    component: () => import('@/pages/user/product/ProductCategory.vue'),
    meta: {
      title: ['menu-user.category-management'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/product/category/add',
    name: 'AddProductCategory',
    component: () => import('@/pages/user/product/ProductCategoryDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'user.category' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/product/category/detail/:id',
    name: 'ProductCategoryDetail',
    component: () => import('@/pages/user/product/ProductCategoryDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'user.category' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/yoga/yoga-credit-order',
    name: 'YogaCreditPackageOrderList',
    component: () => import('@/pages/user/yoga-credit/YogaCreditPackageOrderList.vue'),
    meta: {
      title: ['menu-user.credit-order'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
    props: true
  },

  {
    path: '/yoga/yoga-credit-order/detail/:id',
    name: 'YogaCreditPackageOrderDetail',
    component: () => import('@/pages/user/yoga-credit/YogaCreditPackageOrderDetail.vue'),
    meta: {
      title: ['common.detail-text', { name: 'menu-user.credit-order' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/yoga/yoga-credit',
    name: 'YogaCreditPackageList',
    component: () => import('@/pages/user/yoga-credit/YogaCreditPackageList.vue'),
    meta: {
      title: ['menu-user.credit-setting'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/yoga/yoga-credit/add',
    name: 'AddYogaCreditPackage',
    component: () => import('@/pages/user/yoga-credit/YogaCreditPackageDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'yoga-credit.credit' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/yoga/yoga-credit/detail/:id',
    name: 'YogaCreditPackageDetail',
    component: () => import('@/pages/user/yoga-credit/YogaCreditPackageDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'yoga-credit.credit' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/yoga/yoga-course/timetable',
    name: 'YogaCourseTimetable',
    component: () => import('@/pages/user/yoga-course/YogaCourseTimetable.vue'),
    meta: {
      title: ['menu-user.course-management'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/yoga/yoga-course/add',
    name: 'AddYogaCourse',
    component: () => import('@/pages/user/yoga-course/YogaCourseDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'menu-user.course' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
    props: true
  },

  {
    path: '/yoga/yoga-course/detail/:id',
    name: 'YogaCourseDetail',
    component: () => import('@/pages/user/yoga-course/YogaCourseDetail.vue'),
    meta: {
      title: ['common.detail-text', { name: 'menu-user.course' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },



  {
    path: '/order/order-list',
    name: 'OrderList',
    component: () => import('@/pages/user/order/OrderList.vue'),
    meta: {
      title: ['menu-user.order'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
    props: true,
  },
  {
    path: '/order/order-list/detail/:id',
    name: 'OrderDetail',
    component: () => import('@/pages/user/order/OrderDetail.vue'),
    meta: {
      title: ['menu-user.order'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/promotion/promotion-list',
    name: 'PromotionList',
    component: () => import('@/pages/user/promotion/PromotionList.vue'),
    meta: {
      title: ['menu-user.promotion'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/promotion/add',
    name: 'AddPromotion',
    component: () => import('@/pages/user/promotion/PromotionDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'menu-user.promotion' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/promotion/detail/:id',
    name: 'PromotionDetail',
    component: () => import('@/pages/user/promotion/PromotionDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'menu-user.promotion' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/setting/general-setting',
    name: 'GeneralSetting',
    component: () => import('@/pages/user/setting/GeneralSetting.vue'),
    meta: {
      title: ['menu-user.general-setting'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/setting/payment-setting',
    name: 'PaymentSetting',
    component: () => import('@/pages/user/setting/PaymentSetting.vue'),
    meta: {
      title: ['menu-user.payment-setting'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/setting/delivery-setting',
    name: 'DeliverySetting',
    component: () => import('@/pages/user/setting/DeliverySetting.vue'),
    meta: {
      title: ['menu-user.delivery-setting'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/setting/promotion-setting',
    name: 'PromotionSetting',
    component: () => import('@/pages/user/setting/PromotionSetting.vue'),
    meta: {
      title: ['menu-user.promotion-setting'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/appointment-list',
    name: 'AppointmentList',
    component: () => import('@/pages/user/appointment/AppointmentList.vue'),
    meta: {
      title: ['appointment.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/appointment-list/sorting',
    name: 'AppointmentSorting',
    component: () => import('@/pages/user/appointment/AppointmentSorting.vue'),
    meta: {
      title: ['appointment.sorting'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/appointment-list/add',
    name: 'AddAppointment',
    component: () => import('@/pages/user/appointment/AppointmentDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'appointment.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/appointment-list/detail/:id',
    name: 'AppointmentDetail',
    component: () => import('@/pages/user/appointment/AppointmentDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'appointment.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/appointment-record-list',
    name: 'AppointmentRecordList',
    component: () => import('@/pages/user/appointment/AppointmentRecordList.vue'),
    meta: {
      title: ['appointment.appointment-record'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
    props: true,
  },
  {
    path: '/appointment-record-list/:id',
    name: 'AppointmentRecordDetail',
    component: () => import('@/pages/user/appointment/AppointmentRecordDetail.vue'),
    meta: {
      title: ['appointment.appointment-detail'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/appointment/category',
    name: 'AppointmentCategory',
    component: () => import('@/pages/user/appointment/AppointmentCategory.vue'),
    meta: {
      title: ['appointment.appointment-category'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/appointment/category/add',
    name: 'AddAppointmentCategory',
    component: () => import('@/pages/user/appointment/AppointmentCategoryDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'appointment.appointment-category' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/appointment/category/detail/:id',
    name: 'AppointmentCategoryDetail',
    component: () => import('@/pages/user/appointment/AppointmentCategoryDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'appointment.appointment-category' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/medi-appointment-list',
    name: 'MediAppointmentList',
    component: () => import('@/pages/user/appointment/medi/MediAppointmentList.vue'),
    meta: {
      title: ['appointment.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/medi-appointment-list/sorting',
    name: 'MediAppointmentSorting',
    component: () => import('@/pages/user/appointment/medi/MediAppointmentSorting.vue'),
    meta: {
      title: ['appointment.sorting'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/medi-appointment-list/add',
    name: 'AddMediAppointment',
    component: () => import('@/pages/user/appointment/medi/MediAppointmentDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'appointment.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/medi-appointment-list/detail/:id',
    name: 'MediAppointmentDetail',
    component: () => import('@/pages/user/appointment/medi/MediAppointmentDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'appointment.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/medi-appointment-record-list',
    name: 'MediAppointmentRecordList',
    component: () => import('@/pages/user/appointment/medi/MediAppointmentRecordList.vue'),
    meta: {
      title: ['appointment.appointment-record'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
    props: true,
  },
  {
    path: '/medi-appointment-record-list/:id',
    name: 'MediAppointmentRecordDetail',
    component: () => import('@/pages/user/appointment/medi/MediAppointmentRecordDetail.vue'),
    meta: {
      title: ['appointment.appointment-detail'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/medi-appointment/category',
    name: 'MediAppointmentCategory',
    component: () => import('@/pages/user/appointment/medi/MediAppointmentCategory.vue'),
    meta: {
      title: ['appointment.appointment-category'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/medi-appointment/category/add',
    name: 'AddMediAppointmentCategory',
    component: () => import('@/pages/user/appointment/medi/MediAppointmentCategoryDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'appointment.appointment-category' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/medi-appointment/category/detail/:id',
    name: 'MediAppointmentCategoryDetail',
    component: () => import('@/pages/user/appointment/medi/MediAppointmentCategoryDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'appointment.appointment-category' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/monthly-fee-record',
    name: 'MonthlyFeeRecord',
    component: () => import('@/pages/user/monthly-fee/MonthlyFeeRecord.vue'),
    meta: {
      title: ['monthly-fee.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/monthly-fee-record/add',
    name: 'AddMonthlyFeeRecord',
    component: () => import('@/pages/user/monthly-fee/AddMonthlyFeeRecord.vue'),
    meta: {
      title: ['common.add-new', { name: 'monthly-fee.monthly-record' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/monthly-fee-record/user/:id',
    name: 'MonthlyFeeUserRecord',
    component: () => import('@/pages/user/monthly-fee/MonthlyFeeUserRecord.vue'),
    meta: {
      title: ['monthly-fee.user-monthly-fee-record'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/enquiry-list',
    name: 'EnquiryList',
    component: () => import('@/pages/user/enquiry/EnquiryList.vue'),
    meta: {
      title: ['menu-user.enquiry'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/medi-enquiry-list',
    name: 'MediEnquiryList',
    component: () => import('@/pages/user/enquiry/MediEnquiryList.vue'),
    meta: {
      title: ['menu-user.enquiry'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/staff-account-list',
    name: 'StoreStaffAccountList',
    component: () => import('@/pages/user/store-staff-account/StoreStaffAccountList.vue'),
    meta: {
      title: ['store-staff-account.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/staff-account-list/add',
    name: 'AddStoreStaffAccount',
    component: () => import('@/pages/user/store-staff-account/StoreStaffAccountDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'store-staff-account.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/staff-account-list/:id',
    name: 'StoreStaffAccountDetail',
    component: () => import('@/pages/user/store-staff-account/StoreStaffAccountDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'store-staff-account.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/supplier-list',
    name: 'SupplierList',
    component: () => import('@/pages/user/supplier/SupplierList.vue'),
    meta: {
      title: ['supplier.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/supplier-list/add',
    name: 'AddSupplier',
    component: () => import('@/pages/user/supplier/SupplierDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'supplier.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/supplier-list/:id',
    name: 'SupplierDetail',
    component: () => import('@/pages/user/supplier/SupplierDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'supplier.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/purchase-order-list',
    name: 'PurchaseOrderList',
    component: () => import('@/pages/user/purchase-order/PurchaseOrderList.vue'),
    meta: {
      title: ['purchase-order.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/purchase-order-list/add',
    name: 'AddPurchaseOrder',
    component: () => import('@/pages/user/purchase-order/PurchaseOrderDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'purchase-order.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/purchase-order-list/:id',
    name: 'PurchaseOrderDetail',
    component: () => import('@/pages/user/purchase-order/PurchaseOrderDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'purchase-order.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/staff-log',
    name: 'StaffLog',
    component: () => import('@/pages/user/StaffLog.vue'),
    meta: {
      title: ['staff-log.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/push-notification-list',
    name: 'PushNotificationList',
    component: () => import('@/pages/user/push-notification/PushNotificationList.vue'),
    meta: {
      title: ['push-notification.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/push-notification-list/add',
    name: 'AddPushNotification',
    component: () => import('@/pages/user/push-notification/PushNotificationDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'push-notification.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/push-notification-list/:id',
    name: 'PushNotificationDetail',
    component: () => import('@/pages/user/push-notification/PushNotificationDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'push-notification.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/renting-record',
    name: 'RentingRecord',
    component: () => import('@/pages/user/renting/RentingRecord.vue'),
    meta: {
      title: ['renting.renting-record'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/renting-record/detail/:id',
    name: 'RentingRecordDetail',
    component: () => import('@/pages/user/renting/RentingRecordDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'renting.rent' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/renting-product-list',
    name: 'RentingProductList',
    component: () => import('@/pages/user/renting/RentingProductList.vue'),
    meta: {
      title: ['renting.product-list'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/renting-product-list/add',
    name: 'AddRentingProduct',
    component: () => import('@/pages/user/renting/RentingProductDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'renting.product' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },
  {
    path: '/renting-product-list/detail/:id',
    name: 'RentingProductDetail',
    component: () => import('@/pages/user/renting/RentingProductDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'renting.product' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/quotation-list',
    name: 'QuotationList',
    component: () => import('@/pages/user/quotation/QuotationList.vue'),
    meta: {
      title: ['quotation.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/quotation-list/add',
    name: 'AddQuotation',
    component: () => import('@/pages/user/quotation/QuotationDetail.vue'),
    meta: {
      title: ['common.add-new', { name: 'quotation.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/quotation-list/detail/:id',
    name: 'QuotationDetail',
    component: () => import('@/pages/user/quotation/QuotationDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'quotation.title' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/quotation-management',
    name: 'QuotationManagement',
    component: () => import('@/pages/user/quotation/QuotationManagement.vue'),
    meta: {
      title: ['quotation.management'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/quotation-management/detail/:id',
    name: 'QuotationManagementDetail',
    component: () => import('@/pages/user/quotation/QuotationManagementDetail.vue'),
    meta: {
      title: ['common.setting-text', { name: 'quotation.management' }],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },

  {
    path: '/product-booking/list',
    name: 'ProductBookingList',
    component: () => import('@/pages/user/product-booking/ProductBookingList.vue'),
    meta: {
      title: ['product-booking.title'],
      requireAdminAuth: true,
      requireUserAuth: true,
    },
  },


  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: { title: 'Error' },
  },
];

const router: VueRouter = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history', // abstract, hash, history
  scrollBehavior: async (to: Route, _from: Route, savedPosition: void | Position): Promise<PositionResult> => {
    // https://vuetifyjs.com/features/scrolling/#router3067306e4f7f7528
    let scrollTo: VuetifyGoToTarget = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return { x: 0, y: await goTo(scrollTo) };
  },
  routes,
});

router.beforeEach((_to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
  // TODO: verify_store_account
  if (_to?.meta?.requireAdminAuth || _to?.meta?.requireUserAuth) {
    const staffLoginData = localStorage.getItem('aj_cms_internal_account_data');
    const storeAccountData = localStorage.getItem('aj_cms_store_account_data');
    const storeWebsiteData = localStorage.getItem('aj_cms_store_website_data');
    const purchasePlanData = localStorage.getItem('aj_cms_purchased_plan_data');

    const isStaffLogin = vm.$validator.DataValid(staffLoginData);
    const isUserLogin =
      vm.$validator.DataValid(storeAccountData) &&
      vm.$validator.DataValid(storeWebsiteData) //&& vm.$validator.DataValid(purchasePlanData);

    if (_to?.meta?.requireAdminAuth && isStaffLogin) {
      if (_to?.meta?.requireUserAuth) {
        if (isUserLogin) {
          next();
        } else {
          if (_to.path !== '/staff-store-list') {
            next('/staff-store-list');
          } else {
            next();
          }
        }
      } else {
        next();
      }
    } else if (_to?.meta?.requireUserAuth && isUserLogin) {
      next();
    } else {
      if (_to.path !== '/login') {
        next('/login');
      } else {
        next();
      }
    }
  } else if (_to?.meta?.skipAuth) {
    const staffLoginData = localStorage.getItem('aj_cms_internal_account_data');
    const storeAccountData = localStorage.getItem('aj_cms_store_account_data');
    const storeWebsiteData = localStorage.getItem('aj_cms_store_website_data');
    const purchasePlanData = localStorage.getItem('aj_cms_purchased_plan_data');

    const isStaffLogin = vm.$validator.DataValid(staffLoginData);
    const isUserLogin =
      vm.$validator.DataValid(storeAccountData) &&
      vm.$validator.DataValid(storeWebsiteData) //&& vm.$validator.DataValid(purchasePlanData);

    const adminAllowed = vm.$validator.DataValid(_to?.meta?.adminAllow) ? _to?.meta?.adminAllow : false

    if (isStaffLogin && adminAllowed) {
      next();
    } else if ((isStaffLogin && isUserLogin) || isUserLogin) {
      next('/my-store');
    } else if (isStaffLogin) {
      next('/staff-store-list');
    } else {
      next();
    }
  } else {
    next();
  }

  // @see https://github.com/championswimmer/vuex-persist#how-to-know-when-async-store-has-been-replaced
  // await store.restored;

  next();
});

const DEFAULT_TITLE = 'CMS';

router.afterEach((to, from) => {
  // Hide Loading
  Vue.nextTick(() => {
    let title = ""
    if (to['meta'] !== undefined) {
      // (to['meta'] !== undefined ? to['meta'].title : '')
      const titleArr = to['meta'].title;
      if (titleArr.length > 1) {
        const keys = Object.keys(titleArr[1]);
        const obj: any = {}
        obj[keys[0]] = i18n.t(titleArr[1][keys[0]])
        title = i18n.t(titleArr[0], obj)
      } else {
        title = i18n.t(titleArr[0])
      }
    }

    document.title = `${title} - ${DEFAULT_TITLE}`;
  });
});

export default router;
