var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex-grow-1"},[(_vm.$validator.DataValid(_vm.label))?_c('div',{staticClass:"label-text"},[_c('div',{staticClass:"mr-2 d-flex align-center justify-space-between",staticStyle:{"width":"100%"}},[_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.label)}}),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.$validator.DataValid(_vm.tooltipText),"content-class":"text-field-tooltip","max-width":'80%'},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.$validator.DataValid(_vm.tooltipText))?_c('v-icon',_vm._g(_vm._b({staticClass:"fi fi-ss-info accent--text tooltip-icon"},'v-icon',attrs,false),on)):_vm._e()]}}],null,false,4249210795)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.tooltipText))}})])],1),(_vm.showSettingBtn)?_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":_vm.clickSettingBtn}},[_c('v-icon',[_vm._v("mdi-cog")])],1):_vm._e()],1),(_vm.$validator.DataValid(_vm.horizontalLabel))?_c('div',{staticClass:"horizontal-label-text"},[_vm._v(" "+_vm._s(_vm.horizontalLabel)+" ")]):_vm._e()]):_vm._e(),(_vm.$validator.DataValid(_vm.subLabel))?_c('div',{staticClass:"sub-label-text"},[_vm._v(" "+_vm._s(_vm.subLabel)+" ")]):_vm._e(),_c('v-text-field',_vm._g(_vm._b({class:{
      'custom-text-field': true,
      'custom-text-field-counter': _vm.counter,
    },attrs:{"solo":_vm.solo,"filled":_vm.filled,"outlined":_vm.outlined,"hide-details":"auto","readonly":_vm.readonly,"flat":_vm.flat && _vm.solo,"dense":_vm.dense,"prefix":_vm.prefix,"suffix":_vm.suffix,"placeholder":_vm.placeholder,"rules":[
      ..._vm.customChecking,
      v => !_vm.required || _vm.$validator.DataValid(v),
      v => {
        if (_vm.allowEmptyValidate && !_vm.required) {
          if (!_vm.$validator.DataValid(v)) {
            return true;
          }
        }

        return !_vm.validateEmail || _vm.$validator.emailValid(v);
      },
      v => {
        if (_vm.allowEmptyValidate && !_vm.required) {
          if (!_vm.$validator.DataValid(v)) {
            return true;
          }
        }

        return !_vm.validatePhone || _vm.$validator.mobileValid(v);
      },
      v => {
        if (_vm.allowEmptyValidate && !_vm.required) {
          if (!_vm.$validator.DataValid(v)) {
            return true;
          }
        }
        return !_vm.validateNumber || _vm.$validator.isNumber(v);
      },
      v => {
        if (_vm.allowEmptyValidate && !_vm.required) {
          if (!_vm.$validator.DataValid(v)) {
            return true;
          }
        }
        return !_vm.validateFloating || _vm.$validator.isFloating(v);
      },
      v => {
        if (_vm.allowEmptyValidate && !_vm.required) {
          if (!_vm.$validator.DataValid(v)) {
            return true;
          }
        }

        return !_vm.validateNegativeNumber || _vm.$validator.isNegativeNumber(v);
      },
      v => {
        if (_vm.allowEmptyValidate && !_vm.required) {
          if (!_vm.$validator.DataValid(v)) {
            return true;
          }
        }

        return !_vm.validateNegativeNumber || _vm.$validator.isNegativeNumber(v);
      },
      v => {
        if (_vm.allowEmptyValidate && !_vm.required) {
          if (!_vm.$validator.DataValid(v)) {
            return true;
          }
        }

        return !_vm.validateAlphaNumeric || _vm.$validator.isAlphaNumeric(v);
      },
      v => {
        if (_vm.allowEmptyValidate && !_vm.required) {
          if (!_vm.$validator.DataValid(v)) {
            return true;
          }
        }

        return !_vm.validateURLSlug || _vm.$validator.urlValid(v);
      },
      v => {
        if (_vm.allowEmptyValidate && !_vm.required) {
          if (!_vm.$validator.DataValid(v)) {
            return true;
          }
        }

        return !_vm.validateCustomPageLink || _vm.$validator.customPageLinkValid(v);
      },

      v => !_vm.counter || v.length <= _vm.maxCounter,
    ],"value":_vm.fieldValue,"append-icon":_vm.appendIcon,"counter-value":_vm.getCounterVal,"counter":_vm.counter,"error":_vm.customErrorState,"error-messages":_vm.customErrorText,"prepend-inner-icon":_vm.prependInnerIcon,"hint":_vm.hint,"persistent-hint":_vm.$validator.DataValid(_vm.hint)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();},"change":function($event){return _vm.updateValue($event)},"keypress":function($event){return _vm.formatInput($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getSearchValue.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showScanBtn)?_c('v-btn',{attrs:{"text":"","small":"","color":"colorLightGrey"}},[_c('v-icon',{staticClass:"barcode-scan-icon mr-4",attrs:{"small":""}}),_vm._v(" "+_vm._s(_vm.$t('common.scan'))+" ")],1):_vm._e()]},proxy:true}])},'v-text-field',_vm.attrs,false),_vm.activator))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }