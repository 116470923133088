/** Config store */
import type {
    ActionContext,
    ActionTree,
    GetterTree,
    Module,
    MutationTree,
} from 'vuex';

import type { RootState } from '.';
import Cookies from 'js-cookie';

/** Config State */
export interface ConfigState {
    /** Dark Theme mode */
    themeDark: boolean;
    /** Dark Theme mode */
    miniDrawer: boolean;
    /** Language */
    locale: string;
    /* For store basic setting */
    basicSetting: boolean;
    /** Application key */
    applicationKey: string;
    /** showTheTrialBox */
    showTrialBox: boolean;
}

declare const navigator: any;

/* const browserColorScheme: boolean =
    window.matchMedia('(prefers-color-scheme: dark)').matches ?? false; */

const browserLang: any =
    (navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator['userLanguage']) ?? '';

const cookieConfig: object = {
    path: '/',
    expires: 120,
    sameSite: 'strict',
    secure: true,
};

/** Default Configure state value */
const state: ConfigState = {
    // dark-mode
    themeDark:
        Cookies.get('themeDark') !== undefined
            ? Cookies.get('themeDark') === '1'
            : false,
    // mini variant Nav Drawer
    miniDrawer: false,
    // language
    locale:
        Cookies.get('userLang') !== undefined
            ? Cookies.get('userLang') || 'zh-HK'
            : browserLang.split('-')[0] === 'en'
                ? 'en'
                : 'zh-HK',
    basicSetting: false,
    applicationKey: "",
    showTrialBox: false,
};

// sync to body CSS
state.themeDark
    ? document.body.classList.add('dark')
    : document.body.classList.remove('dark');

document.documentElement.setAttribute("lang", state.locale)

// refresh theme cookie
if (Cookies.get('themeDark') !== undefined) {
    Cookies.set(
        'themeDark',
        Cookies.get('themeDark') === '1' ? '1' : '0',
        cookieConfig
    );
}

/** Getters */
const getters: GetterTree<ConfigState, RootState> = {
    themeDark: (s): boolean => s.themeDark,
    miniDrawer: (s): boolean => s.miniDrawer,
    locale: (s): string => s.locale,
    basicSetting: (s): boolean => s.basicSetting,
    applicationKey: (s): string => s.applicationKey,
    showTrialBox: (s): boolean => s.showTrialBox
};

/** Mutations */
const mutations: MutationTree<ConfigState> = {
    toggleTheme(s) {
        s.themeDark = !s.themeDark;
        Cookies.set('themeDark', s.themeDark ? '1' : '0', cookieConfig);
        s.themeDark
            ? document.body.classList.add('dark')
            : document.body.classList.remove('dark');
    },
    setDrawer(s, isMini: boolean) {
        s.miniDrawer = isMini;
    },
    setLocale(s, locale: string) {
        s.locale = locale;
        Cookies.set('userLang', s.locale, cookieConfig);
    },
    startBasicSetting(s, basicSetting: boolean) {
        s.basicSetting = basicSetting;
    },
    setApplicationKey(s, applicationKey: string) {
        s.applicationKey = applicationKey;
    },
    trialBoxShouldShow(s, showTrialBox: boolean) {
        s.showTrialBox = showTrialBox
    }
};

/** Action */
const actions: ActionTree<ConfigState, RootState> = {
    /**
     * Switch Dark/Light mode.
     *
     * @param context - Vuex Context
     */
    toggleTheme(context: ActionContext<ConfigState, RootState>) {
        context.commit('toggleTheme');
    },
    /**
     * Change nav drawer is mini or not.
     *
     * @param context - Vuex Context
     * @param isMini - Locale code
     */
    setDrawer(context: ActionContext<ConfigState, RootState>, isMini = true) {
        context.commit('setDrawer', isMini);
    },
    /**
     * Change locale.
     *
     * @param context - Vuex Context
     * @param locale - Locale code
     */
    setLocale(context: ActionContext<ConfigState, RootState>, locale = 'en') {
        context.commit('setLocale', locale);
    },

    /**
* Start Basic Setting
*
* @param context - Vuex Context
* @param progress - setting progress
*/
    startBasicSettingProgress(
        context: ActionContext<ConfigState, RootState>,
        basicSetting: boolean = false
    ) {
        context.commit('startBasicSetting', basicSetting);
    },
    /**
* Start Basic Setting
*
* @param context - Vuex Context
* @param progress - setting progress
*/
    saveApplicationKey(
        context: ActionContext<ConfigState, RootState>,
        key: string = ""
    ) {
        context.commit('setApplicationKey', key);
    },
    /**
* Toggle Trial Box
*
* @param context - Vuex Context
* @param trial - show / hide trial box
*/
    setTrialBox(
        context: ActionContext<ConfigState, RootState>,
        key: boolean = false
    ) {
        context.commit('trialBoxShouldShow', key);
    },
};

/** VuexStore */
const ConfigModule: Module<ConfigState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default ConfigModule;
