import VueCompositionAPI, { createApp } from '@vue/composition-api';
import '@/styles/style.scss'
import '@/styles/fonts.scss'
import Vue from 'vue';

import HighchartsVue, { Chart } from 'highcharts-vue';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import router from './router';
import store from './store';
import { XHR } from './XHR';

import { CommonFunc } from './prototype/CommonFunc'
import { Formatter } from './prototype/Formatter'
import { Validator } from './prototype/Validator'
import { PDFFunc } from './prototype/PDFFunc';

import App from './App.vue';
import PageHeaderSlot from './components/PageHeaderSlot.vue'
import TextInput from './components/FormField/TextInput.vue'
import SelectField from './components/FormField/SelectField.vue'
import UploadField from './components/FormField/UploadField.vue'

import AuthMixin from '@/mixins/AuthMixin';

Vue.use(HighchartsVue);
Vue.use(VueCompositionAPI);
Vue.use(CommonFunc);
Vue.use(Formatter);
Vue.use(Validator);
Vue.use(PDFFunc);
Vue.use(XHR);

Vue.mixin(AuthMixin);

Vue.config.productionTip = false;

Vue.component('highcharts', Chart);

Vue.filter('formatOrderStatus', function (value: string) {
  if (!value) return ''

  let result = ''
  switch (value) {
    case 'awaiting_payment':
      result = i18n.t('status.awaiting-payment').toString()
      break
    case 'unverified_payment':
      result = i18n.t('status.unverified-payment').toString()
      break
    default:
      result = i18n.t(`status.${value}`).toString()
  }

  return result
});

Vue.filter('formatPaymentStatus', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'no_payment_record':
      result = i18n.t('status.awaiting-payment').toString()
      break
    case 'succeeded':
      result = i18n.t('status.payment-success').toString()
      break
    case 'failed':
      result = i18n.t('status.payment-fail').toString()
      break
    case 'refunded':
      result = i18n.t('status.refunded').toString()
      break
    case 'unverified_payment':
      result = i18n.t('status.unverified-payment').toString()
      break
  }

  return result
});

Vue.filter('formatStatus', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'active':
      result = i18n.t('status.active').toString()
      break
    case 'inactive':
      result = i18n.t('status.inactive').toString()
      break
  }

  return result
});


Vue.filter('formatYogaCourseAttendanceStatus', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'pending':
      result = i18n.t('status.not-start').toString()
      break
    case 'show':
      result = i18n.t('status.attend').toString()
      break
    case 'noshow':
      result = i18n.t('status.absent').toString()
      break
  }

  return result
});

Vue.filter('formatCashDollarAction', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'order':
      result = i18n.t('member.order').toString()
      break
    case 'admin':
      result = i18n.t('member.admin').toString()
      break
    case 'welcome_cash_dollar':
      result = i18n.t('member.welcome-cash-dollar').toString()
      break
    case 'refund_order':
      result = i18n.t('member.order-refund').toString()
      break
  }

  return result
});

Vue.filter('formatAppointmentStatus', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'accept':
      result = i18n.t('appointment.accept-appointment').toString()
      break
    case 'stop':
      result = i18n.t('appointment.stop-appointment').toString()
      break
  }

  return result
});

Vue.filter('formatAppointmentRecordStatus', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'cancelled':
      result = i18n.t('status.cancelled').toString()
      break
    case 'success':
      result = i18n.t('status.appointment-success').toString()
      break
    case 'completed':
      result = i18n.t('status.completed').toString()
      break
  }

  return result
});

Vue.filter('formatAppointmentSelectTypeStatus', function (value: string) {
  if (!value) return ''

  let result = ''
  const day = i18n.t('day-full');

  if (value === 'everyday') {
    result = i18n.t('common.everyday').toString()
  } else if (value === 'specific_date') {
    result = i18n.t('common.specific-date').toString()
  } else if (value === 'mon') {
    result = day[1]
  } else if (value === 'tue') {
    result = day[2]
  } else if (value === 'wed') {
    result = day[3]
  } else if (value === 'thu') {
    result = day[4]
  } else if (value === 'fri') {
    result = day[5]
  } else if (value === 'sat') {
    result = day[6]
  } else if (value === 'sun') {
    result = day[0]
  }

  return result
});

Vue.filter('formatMonthlyFeeRecordStatus', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'pending':
      result = i18n.t('monthly-fee.pending').toString()
      break
    case 'paid':
      result = i18n.t('status.paid').toString()
      break
    case 'cancelled':
      result = i18n.t('status.cancelled').toString()
      break
  }

  return result
});

Vue.filter('formatStaffAccountStatus', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'admin':
      result = i18n.t('member.admin').toString()
      break
    case 'sales':
      result = i18n.t('store-staff-account.sales').toString()
      break
  }

  return result
});

Vue.filter('formatLogRelatedDataType', function (value: string) {
  if (!value) return '-'

  let result = '-'

  const type = i18n.t(`data-type.${value}`);
  if (type !== undefined && type !== null && type !== "") {
    result = type
  }

  return result
});

Vue.filter('formatLogAction', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'add':
      result = i18n.t('common.add').toString()
      break
    case 'edit':
      result = i18n.t('common.edit').toString()
      break
    case 'delete':
      result = i18n.t('common.delete').toString()
      break
  }

  return result
});

Vue.filter('formatRentingRecordStatus', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'cancelled':
      result = i18n.t('status.cancelled').toString()
      break
    case 'success':
      result = i18n.t('renting.success').toString()
      break
    case 'completed':
      result = i18n.t('renting.completed').toString()
      break
  }

  return result
});


Vue.filter('formatQuotationStatus', function (value: string) {
  if (!value) return '-'

  let result = '-'
  switch (value) {
    case 'pending':
      result = i18n.t('quotation.pending').toString()
      break
    case 'approved':
      result = i18n.t('quotation.approved').toString()
      break
    case 'not_approved':
      result = i18n.t('quotation.not_approved').toString()
      break
    case 'processing':
      result = i18n.t('quotation.processing').toString()
      break
    case 'completed':
      result = i18n.t('quotation.completed').toString()
      break
    case 'cancelled':
      result = i18n.t('quotation.cancelled').toString()
      break
  }

  return result
});


const app = createApp({
  render: (h: any) => h(App),
  router,
  store,
  vuetify,
  i18n,
});

app.component('PageHeaderSlot', PageHeaderSlot);
app.component('TextInput', TextInput);
app.component('SelectField', SelectField);
app.component('UploadField', UploadField);

app.mount('#app');
