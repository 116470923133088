var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[_c('v-app-bar',{style:({ 'margin-left': _vm.$vuetify.breakpoint.mdAndDown ? '0' : '8px' }),attrs:{"flat":"","app":"","color":"white","height":"60"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.isDrawerOpen = !_vm.isDrawerOpen}}},[_c('v-icon',{staticClass:"fi fi-rr-arrow-alt-square-right",staticStyle:{"font-size":"22px"},attrs:{"color":"colorBlack"}})],1):_vm._e(),_c('v-spacer'),(_vm.$vuetify.breakpoint.lgAndUp && _vm.showShopGuide)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{staticClass:"mr-7",style:({
          color: hover ? 'var(--v-colorBlack-base)' : 'var(--v-primary-base)',
        }),attrs:{"text":""}},[_c('v-icon',{staticClass:"fi fi-rr-search-alt mr-4"}),_vm._v(" "+_vm._s(_vm.$t('menu-user.shop-guide'))+" ")],1)]}}],null,false,2989254026)}):_vm._e(),(_vm.isUser && !_vm.storeDisabled && _vm.showViewStoreBtn)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{class:{ 'mr-7': _vm.$vuetify.breakpoint.lgAndUp },style:({
          'box-shadow': hover
            ? `inset ${_vm.btnLength}px 0 0 0 var(--v-primary-base)`
            : 'inset 0 0 0 0 var(--v-primary-base)',
        }),attrs:{"id":"hover-btn","depressed":"","color":"secondary"},on:{"click":function($event){return _vm.openStoreWebsiteLink()}}},[_c('v-icon',{staticClass:"fi fi-rr-store-alt mr-4"}),_vm._v(" "+_vm._s(_vm.$t('menu-user.view-store'))+" ")],1)]}}],null,false,3519822718)}):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?[_c('div',{staticClass:"divider"}),_c('v-avatar',{staticClass:"pa-1 mr-4 app-bar-icon",attrs:{"color":"#ffffff","size":"35"}},[(_vm.$validator.DataValid(_vm.userIcon))?_c('img',{attrs:{"src":_vm.userIcon}}):_vm._e()]),_c('v-menu',{attrs:{"flat":"","bottom":"","offset-y":"","nudge-bottom":"10","rounded":"lg","content-class":"elevation-0 elevation-custom","transition":"slide-y-transition","close-on-content-click":!_vm.disableCloseMenu},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"user-menu-open-button",attrs:{"depressed":"","text":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.userName))]),_c('v-icon',{staticClass:"ml-14"},[_vm._v(" "+_vm._s(_vm.isUserMenuOpen ? _vm.icon.mdiChevronUp : _vm.icon.mdiChevronDown)+" ")])],1)]}}],null,false,1784550010),model:{value:(_vm.isUserMenuOpen),callback:function ($$v) {_vm.isUserMenuOpen=$$v},expression:"isUserMenuOpen"}},[_c('v-list',{staticClass:"px-2",attrs:{"dense":"","nav":""}},[_vm._l((_vm.userNavActionPanel),function(item,index){return _c('NavListItem',{key:index,staticClass:"user-menu-item",attrs:{"activeClass":'user-menu-item-active',"linkName":item.linkName,"name":item.text},on:{"btnClick":function($event){_vm.disableCloseMenu = false}}})}),(_vm.showBackToAdminButton)?_c('NavListItem',{staticClass:"user-menu-item",attrs:{"name":'common.exit-customer-store'},on:{"btnClick":() => {
                _vm.disableCloseMenu = false;
                this.exitStoreView();
                this.$router.replace({ name: 'StaffStoreList' });
              }}}):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":!_vm.disableCloseMenu,"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('NavListItem',{staticClass:"user-menu-item lang-btn",attrs:{"activator":on,"attrs":attrs,"name":'admin.language'},on:{"btnClick":function($event){_vm.disableCloseMenu = true}},scopedSlots:_vm._u([{key:"icon-slot",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.openLangMenu ? _vm.icon.mdiChevronUp : _vm.icon.mdiChevronDown))])],1)]},proxy:true}],null,true)})]}}],null,false,477178775),model:{value:(_vm.openLangMenu),callback:function ($$v) {_vm.openLangMenu=$$v},expression:"openLangMenu"}},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.changeLang('zh-HK')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('common.zh')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeLang('en')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('common.en')))])],1)],1)],1),_c('NavListItem',{staticClass:"user-menu-item logout-btn",attrs:{"icon":'fi fi-rr-sign-in-alt',"name":'common.logout'},on:{"btnClick":() => {
                _vm.disableCloseMenu = false;
                this.logoutAccount();
              }}})],2)],1)]:_vm._e()],2),_c('NavDrawer',{attrs:{"icon":_vm.userIcon,"drawerItemList":_vm.drawerItem,"userNavActionPanel":_vm.userNavActionPanel,"isDrawerOpen":_vm.isDrawerOpen,"showBackToAdminButton":_vm.showBackToAdminButton,"username":_vm.userName},on:{"update:isDrawerOpen":function($event){_vm.isDrawerOpen=$event},"update:is-drawer-open":function($event){_vm.isDrawerOpen=$event}}}),_c('v-main',[_c('div',{class:_vm.mainContentClass},[(!_vm.hideStoreSettingPanel)?_c('StoreSettingPanel',{attrs:{"basicSettingProgress":_vm.basicSettingProgress}}):_vm._e(),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[[_vm._t("default")]],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }