import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import { getCurrentInstance } from '@vue/composition-api';
import '@mdi/font/css/materialdesignicons.css';
import { loadFonts } from './webfontloader';

loadFonts();

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#1B718A',
                secondary: '#04243B',
                accent: '#FFB100',
                colorWhite: '#F2F4F4',
                colorBlack: '#3B3B3B',
                colorGrey:'#888888',
                colorLightGrey:'#A7A7A7',
                error: '#A32828',
                warning: '#e0ac08',
                info: '#4854e0',
                success: '#215003',
                borderColor: '#EAEAEA'
            },
            dark: {
                primary: '#1B718A',
                secondary: '#04243B',
                accent: '#FFB100',
                colorWhite: '#F2F4F4',
                colorBlack: '#3B3B3B',
                colorGrey:'#888888',
                colorLightGrey:'#A7A7A7',
                error: '#A32828',
                warning: '#e0ac08',
                info: '#4854e0',
                success: '#215003',
                borderColor: '#EAEAEA'
            },
        },
        options: {
            // themeCache: {
            //   // https://vuetifyjs.com/features/theme/#section-30ad30e330c330b730e5
            //   get: (key: VuetifyParsedTheme) => {
            //     return localStorage.getItem(JSON.stringify(key));
            //   },
            //   set: (key: VuetifyParsedTheme, value: string) => {
            //     localStorage.setItem(JSON.stringify(key), value);
            //   },
            // },
            customProperties: true,
        },
    },
});

/** Get vuetify instance (For Composition api) */
export function useVuetify() {
    /** Get Instance */
    const instance = getCurrentInstance();
    if (!instance) {
        throw new Error(`Should be used in setup().`);
    }
    // @ts-ignore
    return instance.proxy.$vuetify;
}

