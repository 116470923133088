/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-promise-reject-errors */
// Data formatter
import _Vue from 'vue';
import i18n from '@/plugins/i18n';

export function Validator(Vue: typeof _Vue): void {
    const regex = {
        email:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        phone: /^[23456789][0-9]{7}$/,
        number: /^[0-9]+$/i,
        floating: /^[0-9]+([.]?[0-9]+)?$/i,
        negativeNumber: /^[-]?[0-9]+$/i,
        negativeFloating: /^[-]?[0-9]+([.]?[0-9]+)?$/i,
        alphaNumeric: /^[a-zA-Z0-9]+$/i,
        urlFilter: /^[\u4e00-\u9fff\u3400-\u4dff\uf900-\ufaffA-za-z0-9-_]+$/i,
        // 只能包含字符A-Z,0-9,- _ . ! ~ * ‘ ( )，但不能以連字符號(-)作為開頭或結尾。
        customPageLinkFilter: /^([A-za-z0-9_.!~*'\(\)]|([A-za-z0-9_.!~*'\(\)]+[A-za-z0-9_.!~*'\(\)-]*[A-za-z0-9_.!~*'\(\)]+))$/i,
    }

    const DataValid = (data: any): boolean => {
        if (data !== undefined && data !== null && data !== '') {
            if (typeof data === 'object') {
                return Object.keys(data).length > 0;
            } else if (Array.isArray(data)) {
                return data.length > 0
            }
            return true;
        } else {
            return false;
        }
    }

    Vue.prototype.$validator = {
        DataValid(data: any): boolean {
            return DataValid(data)
        },
        required(value: any): boolean {
            if (DataValid(value) && typeof value === 'string') {
                return value.trim().length > 0
            }

            return DataValid(value)
        },

        emailValid(value: string): boolean | string {
            return new RegExp(regex.email).test(value) || i18n.t('instruction.format-not-match', { type: i18n.t('common.email') }).toString()
        },

        mobileValid(value: string): boolean | string {
            return new RegExp(regex.phone).test(value) || i18n.t('instruction.format-not-match', { type: i18n.t('common.phone') }).toString()
        },

        isNumber(value: string): boolean | string {
            // true = number only
            return new RegExp(regex.number).test(value) || i18n.tc('instruction.force-input', 2, { name: i18n.t('common.integer') })
        },

        isFloating(value: string): boolean | string {
            return new RegExp(regex.floating).test(value) || i18n.tc('instruction.force-input', 1, { name: i18n.t('common.decimal') })
        },

        isNegativeNumber(value: string): boolean | string {
            return new RegExp(regex.negativeNumber).test(value) || i18n.t('instruction.force-pos-nev', { name: i18n.t('common.integer') }).toString()
        },

        isNegativeFloating(value: string): boolean | string {
            return new RegExp(regex.negativeFloating).test(value) || i18n.t('instruction.force-pos-nev', { name: `${i18n.t('common.integer')}/${i18n.t('common.decimal')}` }).toString()
        },

        isAlphaNumeric(value: string): boolean | string {
            return new RegExp(regex.alphaNumeric).test(value) || i18n.tc('instruction.force-input', 2, { name: i18n.t('common.alphanumeric') })
        },

        urlValid(value: string): boolean | string {
            return new RegExp(regex.urlFilter).test(value) || i18n.t('instruction.url-invalid').toString();
        },

        customPageLinkValid(value: string): boolean | string {
            return new RegExp(regex.customPageLinkFilter).test(value) || i18n.t('instruction.custom-page-link-invalid').toString()
        },

        customRegexValid(value: string, customRegex: RegExp | string, resultStr: string = ""): boolean | string {
            let result = new RegExp(customRegex).test(value);

            if (result) {
                return true;
            }

            return DataValid(resultStr) ? resultStr : false;
        },

        largerThanNumber(value: number | string, compareNum: number = 0, isFloating: boolean = true): boolean | string {
            // input cannot smaller than xx

            if (DataValid(value)) {
                const input = isFloating ? parseFloat(value.toString()) : parseInt(value.toString());

                if (input <= compareNum) {
                    return i18n.t('instruction.minimum-number', { number: compareNum }).toString();
                }

                return true;
            }
            return false;
        }
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $validator: {
            DataValid(data: any): boolean,
            validateFileInput(file: File, requiredType: string, requiredExtension: Array<string>): string,
            required(value: any): boolean,
            emailValid(value: string): boolean | string,
            mobileValid(value: string): boolean | string,
            isNumber(value: string): boolean | string,
            isFloating(value: string): boolean | string,
            isNegativeNumber(value: string): boolean | string,
            isNegativeFloating(value: string): boolean | string,
            isAlphaNumeric(value: string): boolean | string,
            urlValid(value: string): boolean | string,
            customPageLinkValid(value: string): boolean | string,
            customRegexValid(value: string, customRegex: RegExp | string, resultStr: string): boolean | string,
            largerThanNumber(value: number | string, compareNum: number, isFloating: boolean): boolean | string
        }
    }
}